import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  ListItemIcon,
  Divider,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";

export default function UserMenu() {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  return (
    <div>
      <IconButton onClick={handleMenuOpen}>
        <Avatar
          src={user.picture}
          alt={user.name}
          sx={{ width: 35, height: 35 }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              alt={user.name}
              src={user.picture}
              sx={{ width: 56, height: 56 }}
            />

            <Typography variant="h6" sx={{ fontSize: 15 }}>
              Welcome,
            </Typography>
            <Typography variant="h6" sx={{ fontSize: 15 }}>
              {user.name}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <Typography>Logout</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}
