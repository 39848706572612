// SX Styles for App Header
import { mainThemeColor } from "../../color/MainThemeColor";

const appBarStyle = {
  backgroundColor: mainThemeColor
}

const typographyStyle = {
  flexGrow: 1,
  marginLeft: "5px",
  fontSize: "30px",
  fontWeight: "bold",
};

const hoverIconStyle = {
  position: "fixed",
  bottom: 150,
  right: 70,
  zIndex: 1000,
  backgroundColor: "rgba(47, 79, 79, 0.7)",
  "&:hover": {
    backgroundColor: "#2f4f4f",
  },
  transition: 'opacity 0.5s ease',
  borderRadius: "50%",
  width: 50, 
  height: 50,
};

export { typographyStyle, hoverIconStyle, appBarStyle };
