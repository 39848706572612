import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import OS from "../components/OS_Logo_2.png";
import { footerStyle, textStyle } from "./FooterStyle";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function FootAppBar() {
  return (
    <AppBar position="static" elevation={0} sx={footerStyle}>
      <Toolbar>
        < img src={OS} height={36.5} width={105.25} alt="Oclussion Solutions Logo" />
        <Box sx={{ flexGrow: 1 }}>
          <Typography align="center" sx={textStyle} >
            Copyright © 2023 Oclussion Solutions. All rights reserved.
          </Typography>
        </Box>
        <Box>
          <a href="https://www.linkedin.com/company/occlusion-solutions/mycompany/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
            <LinkedInIcon />
          </a >
        </Box>
      </Toolbar>
    </AppBar>
  );
}