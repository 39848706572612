import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import OS from "../components/OS_Logo.png";
import DrawerComponent from "../sideBar/SideBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import UserMenu from "../../user/UserMenu";
import AppBar from "@mui/material/AppBar";
import { typographyStyle, appBarStyle } from "./HeaderStyle";
import "./Header.css";

export default function HeadAppBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 40;
      if (show !== isScrolled) setIsScrolled(show);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);

  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" sx={appBarStyle} elevation={isScrolled ? 4 : 0}>
        <Toolbar>
          <img src={OS} height={43} width={40} alt="" />
          <Typography variant="h6" noWrap sx={typographyStyle} component="div">
            Proximity Planner
          </Typography>
          <UserMenu />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ marginRight: 1 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerComponent open={open} handleDrawerClose={handleDrawerClose} />
    </Box>
  );
}
