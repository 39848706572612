//Functions
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "./global-components/authenticationGuard/AuthenticationGuard";
import { useState, lazy, Suspense } from "react";
//Components
import Layout from "./global-components/appBar/Layout";
import LoginLoading from "./global-components/loginLoading/LoginLoading";
//Lazy Load Components
const HomePage = lazy(() => import("./main-pages/home/pages/homePage"));
const HelpPage = lazy(() => import("./main-pages/help/pages/helpPage"));
const LoginPage = lazy(() => import("./main-pages/login/pages/loginPage"));
const RoutingPage = lazy(() => import("./main-pages/routing/pages/routingPage"));


//Main Front End Page
function App() {
  //For the very first load of the page
  const [initialLoad, setInitialLoad] = useState(true);
  //For the routing Data
  const [routingData, setRoutingData] = useState([]);

  return (
    <Router>
      <Layout>
      <Suspense fallback={<LoginLoading />}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/help"
            element={<AuthenticationGuard component={HelpPage} />}
          />
          <Route
            path="/home"
            element={
              <AuthenticationGuard
                component={HomePage}
                initialLoad={initialLoad}
                setInitialLoad={setInitialLoad}
                setRoutingData={setRoutingData}
              />
            }
          />
          <Route
            path="/routing"
            element={
              <AuthenticationGuard
                component={RoutingPage}
                routingData={routingData}
                setRoutingData={setRoutingData}
              />
            }
          />
        </Routes>
        </Suspense>
      </Layout>
    </Router>
  );
}

export default App;
