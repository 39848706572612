import { useLocation } from 'react-router-dom';
import FootAppBar from './footer/Footer.js';
import HeadAppBar from './header/Header.js';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";



const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const { isLoading, isAuthenticated } = useAuth0();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {pathname !== "/" && !isLoading && isAuthenticated ? <HeadAppBar /> : null}
      <Box sx={{ flex: '1 0 auto' }}>
        <div>{children}</div>
      </Box>
      {pathname !== "/" && !isLoading && isAuthenticated ? <FootAppBar /> : null}
    </Box>
  );
};

export default Layout;