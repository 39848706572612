const drawerStyle = {
  width: 240,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: 240,
  },
};

const listItemButton = {
  minHeight: 48,
  justifyContent: "initial",
  px: 2.5,
};

const listItemIcon = {
  minHeight: 0,
  mr: 3,
  justifyContent: "center",
};

export { drawerStyle, listItemButton, listItemIcon };
