// DrawerComponent.js
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
//Icons
import RouteIcon from "@mui/icons-material/Route";
import MapIcon from "@mui/icons-material/Map";
import HelpIcon from "@mui/icons-material/Help";
import ListItemIcon from "@mui/material/ListItemIcon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OSLogo from "../components/OS_Logo_3.png";
//Style
import { drawerStyle, listItemButton, listItemIcon } from "./SideBarStyle";

const FullHeightBox = styled(Box)({
  height: "100%",
});

export default function DrawerComponent({ open, handleDrawerClose }) {
  const navigate = useNavigate();

  return (
    <Drawer
      sx={drawerStyle}
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
    >
      <FullHeightBox display="flex" flexDirection="column">
        <div>
          <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
          </IconButton>
        </div>

        <Divider />
        <List>
          {/* Mapping page */}
          <ListItem
            key="mapPage"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/home");
            }}
          >
            <ListItemButton
              sx={listItemButton}
            >
              <ListItemIcon
                sx={listItemIcon}
              >
                <MapIcon></MapIcon>
              </ListItemIcon>
              <ListItemText primary={"Map"} />
            </ListItemButton>
          </ListItem>

          {/* Routing page */}
          <ListItem
            key="routingPage"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/routing");
            }}
          >
            <ListItemButton
              sx={listItemButton}
            >
              <ListItemIcon
                sx={listItemIcon}
              >
                <RouteIcon></RouteIcon>
              </ListItemIcon>
              <ListItemText primary={"Route"} />
            </ListItemButton>
          </ListItem>

          {/* help page */}
          <ListItem
            key="helpPage"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/help");
            }}
          >
            <ListItemButton
              sx={listItemButton}
            >
              <ListItemIcon
                sx={listItemIcon}
              >
                <HelpIcon></HelpIcon>
              </ListItemIcon>
              <ListItemText primary={"Help"} />
            </ListItemButton>
          </ListItem>
        </List>

        <Box
          mt="auto"
          mb={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img src={OSLogo} alt="OS Logo" height={36.5} width={105.25} />
        </Box>
      </FullHeightBox>
    </Drawer>
  );
}
